<template>
	<div class="rcr-main">
		<span @click="isShow" class="colorWhite line-h80 pointer" :style="getStyle()"><span class="el-icon-info " v-if="orderType!=1"></span> 退改签规则</span>
		<el-dialog
			title="退改签规则"
			:visible.sync="visible"
			:lock-scroll="false"
		     >
			<div class="flex between textLeft" v-if="orderType==11010 || orderType==1?true:false">
				<div style="width: 92px;" class="flex alignItem font16">退票规则</div>
				<div style="width: 608px;"  class="textline">
					<p>(1)没有换取纸质车票，可以在线申请办理退票。</p>
					<p>(2)已经换取纸质车票或者在开车前30分钟之内的，请携带购票时所使用的乘车人有效身份证件原件到车站售票窗口办理；</p>
					<p>(3)开车前8天以上退票的，不收取退票费；票面乘车站开车时间前48小时以上的按票价5%计，24小时以上、不足48小时的按票价10%计，不足24小时的按票价20%计。</p>
					<p>(4)开车前48小时～15天期间内，改签或变更到站至距开车15天以上的其他列车，又在距开车15天前退票的，仍核收5%的退票费。</p>
					<p>(5)根据退票手续费率计算的手续费以0.5元为单位，尾数小于0.25元的舍去、0.25元以上且小于0.75元的计为0.5元、0.75元以上的进为1元。退票费最低按2元计收。</p>
					<p>(6)接口订单，必须一张一张退。如果多张一起退票，系统会自动拆分生成多个退票单；</p>
				</div>
			</div>
			<div class="flex between textLeft marTop20" v-if="orderType==11011 || orderType==1 ?true:false">
				<div style="width: 92px;" class="flex alignItem font16">改签规则</div>
				<div style="width: 608px;" class="textline">
					<p>(1)改签只能对未换取纸质车票；</p>
					<p>(2)可批量改签，批量改签时，选择的新票座位必须一致，并且不能是卧铺；</p>
					<p>(3)一张车票只能办理一次改签，改签后若要退票请到车站办理；</p>
					<p>(4)开车前48h以上，可改签预售期内的车次；开车前48小时以内，可改签车票载明的乘车日期之后预售期内列车，核收改签费。开车前24小时以上、不足48小时改签，按原车票和改签后车票之中低票面价格的5%核收改签费；开车前不足24小时改签，按原车票和改签后车票之中低票面价格的15%核收改签费。开车后在当日24时之前，可改签车票载明的乘车日期之后预售期内列车核收改签费，按原车票和改签后车票之中低票面价格的40%核收改签费；</p>
					<p>(5)开车前48小时～8天期间内，改签至距开车8天以上的其他列车，又在距开车8天前退票的，仍核收5%的退票费。改签后的车票乘车日期在春运期间的，退票时一律按开车时间前不足24小时标准核收退票费；</p>
					<p>(6)新票价大于原票价，补收差额；新票价小于原票价，退还差额，对差额部分核收退票费并执行现行退票费标准。</p>
				</div>
			</div>
		  <span slot="footer" class="dialog-footer">
		    <el-button type="primary" @click="visible = false">关 闭</el-button>
		  </span>
		</el-dialog>
	</div>
</template>

<script>
	export default{
		name: 'RefundChangeRule',
		props: {
			orderType:{
				type: Number,
				default: 1, //1显示全部
			},
			styleColor:{
				type:Object,
				default(){
					return {}
				}
			}
		},
		data() {
			return {
				visible: false,
				
			}
		},
		methods: {
			isShow(){
				this.visible = true;
			},
			getStyle(){
				let obj = {};
				if(this.styleColor){
					obj = this.styleColor
				}
				return obj
			}
		}
	}
</script>

<style scoped lang="scss">
	.rcr-main{
		/deep/ .el-dialog{
			width: 700px;
		}
		/deep/ .el-dialog__header{
			padding: 20px 20px 10px 20px;
		}
		.flex{
			display: flex;
		}
		.between{
			justify-content: space-between;
		}
		.alignItem{
			align-items: center;
		}
		.relative{
			position: relative;
		}
		.absolute{
			position: absolute;
		}
		.left{
			left: 0;
		}
		.colorWhite{
			color: #FFFFFF;
		}
		.textLeft{
			text-align: left;
		}
		.font16{
			font-size: 16px;
		}
		.textline p{
			line-height: 28px;
			margin-bottom: 5px;
		}
		.pointer{
			cursor: pointer;
		}
		.line-h80{
			line-height: 80px;
		}
		.marTop20{
			margin-top: 20px;
		}
	}
</style>
