<template>
	<div class="tl-main" >
		<el-card v-if="showTrLogin">
			<div  class="tl-box">
				<span class="item-l" >
					<img src="@/assets/image/logo12306.png" alt="">
				</span>
				<span class="item-c">
					<p class="p-t-color">
						<span>12306账号</span> 
						<span v-show="!accountInfo">登录</span>
						<span v-show="accountInfo">({{accountInfo}})已登录</span>
					</p>	
					<p class="p-b-color">登录后抢票成功率更高哦</p>
				</span>
				<span class="item-r" @click="getLoginPage">
					{{!accountInfo?'账号登录':'切换账号'}}	
				</span>
			</div>
		</el-card>
		<el-dialog
			:show-close="false"
			:close-on-click-modal="false"
			:visible.sync="loginVisible"
			width="400px"
			>
			<div class="tl-title">
				<img src="@/assets/image/logo12306.png" alt="" style="width: 25px; height: 25px;">
				<span class="tl-tit-text">12306账号登录</span>
			</div>
			<el-form :model="loginData" status-icon :hide-required-asterisk="true" :rules="loginDataRules" ref="loginData" label-position="top" class="tl-content">
				<el-form-item label="账号" prop="username">
					<el-input v-model="loginData.username"></el-input>
				</el-form-item>
				<el-form-item label="密码" prop="password" >
					<el-input v-model="loginData.password"  type="password" show-password></el-input>
				</el-form-item>
				<!-- v-if="endingNumberShow == 1" -->
				<el-form-item label="登录账号绑定证件号后四位" prop="endingNumber">
					<el-input v-model="loginData.endingNumber"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="marTop20">
				<span class="text-left tl-footer40" @click="getRegisterPage">没有账号？注册</span>
				<span class="text-right tl-footer60">
					<el-button @click="cancelLogin" class="btn">取消</el-button>
					<el-button type="primary" @click="accountLogin('loginData')"  class="btn">
						<span  v-if="!loading">登录</span>
						<span  v-if="loading">
							<span class="el-icon-loading"></span>
							登录中...
						</span>
						
					</el-button>
				</span>
			</div>
		</el-dialog>
		<el-dialog
			:show-close="false"
			:close-on-click-modal="false"
			:visible.sync="registerVisible"
			width="400px"
			>
			<div class="tl-title">
				<img src="../../../../../assets/image/logo12306.png" alt="" style="width: 25px; height: 25px;">
				<span class="tl-tit-text">12306账号注册</span>
			</div>
			<el-form :model="registerData" status-icon :hide-required-asterisk="true" :rules="registerDataRules" ref="registerData" label-width="60px" class="form-content">
				<el-form-item label="证件类型" prop="idTypeCode">
					<el-select placeholder="请选择证件类型" v-model="registerData.idTypeCode" >
						<el-option
						    v-for="item in cardList"
						    :key="item.id"
						    :label="item.text"
						    :value="item.id"
						    >
						</el-option>
					</el-select> 
				</el-form-item>
				<el-form-item label="证件号码" prop="idNo" >
					<el-input placeholder="请输入证件号码" v-model="registerData.idNo"   type="password" show-password></el-input> 
				</el-form-item>
				<el-form-item label="姓名" prop="name">
					<el-input placeholder="请输入姓名" v-model="registerData.name" ></el-input> 
				</el-form-item>
				<el-form-item label="性别" prop="gender" v-if="registerData.idTypeCode && (registerData.idTypeCode!='1' && registerData.idTypeCode!='2')">
					<el-select placeholder="请选择性别" v-model="registerData.gender">
						<el-option
							v-for="item in manList"
							:key="item.id"
							:label="item.text"
							:value="item.id"
						></el-option>
					</el-select> 
				</el-form-item>
				<el-form-item label="手机号码" prop="mobileNo">
					<el-input placeholder="请输入手机号码" v-model="registerData.mobileNo"></el-input> 
				</el-form-item>
				<el-form-item label="旅客类型" prop="passengerType" >
					<el-select placeholder="请选择旅客类型" v-model="registerData.passengerType">
						<el-option
							v-for="item in passengerTypeList"
							:key="item.id"
							:label="item.text"
							:value="item.id"
						></el-option>
					</el-select> 
				</el-form-item>
				<el-form-item label="出生日期" prop="birthday"  v-if="registerData.idTypeCode && (registerData.idTypeCode!='1' && registerData.idTypeCode!='2')">
					<el-date-picker
					    v-model="registerData.birthday"
					    type="date"
					    placeholder="请选择出生日期">
					</el-date-picker>
				</el-form-item>
				<el-form-item label="国家" prop="country" v-if="registerData.idTypeCode && (registerData.idTypeCode=='B' || registerData.idTypeCode=='H')">
					<el-select placeholder="请选择国家" v-model="registerData.country">
						<el-option
							v-for="item in countryList"
							:key="item.id"
							:label="item.cName"
							:value="item.by3"
						></el-option>
					</el-select> 
				</el-form-item>
				<el-form-item label="截止日期" prop="validDateEnd"  v-if="registerData.idTypeCode && (registerData.idTypeCode!='1' && registerData.idTypeCode!='2')">
					<el-date-picker
					    v-model="registerData.validDateEnd"
					    type="date"
					    placeholder="请输入身份证件截止日期">
					</el-date-picker>
				</el-form-item>
				<el-form-item label="用户名" prop="username" >
					<el-input placeholder="请输入用户名" v-model="registerData.username"></el-input> 
				</el-form-item>
				<el-form-item label="密码" prop="password">
					<el-input placeholder="请输入密码" v-model="registerData.password" show-password></el-input> 
				</el-form-item>
				<el-form-item label="确认密码" prop="passwordOk" >
					<el-input placeholder="请输入确认密码" v-model="passwordOk"  show-password></el-input> 
				</el-form-item>
				<el-form-item label="邮箱" prop="email"  v-if="registerData.idTypeCode=='C' || registerData.idTypeCode =='G'">
					<el-input placeholder="请输入电子邮箱" v-model="registerData.email"></el-input> 
				</el-form-item>
			</el-form>
			<div slot="footer" class="marTop20 text-right tl-footer60" style="width: 100%;">
				<el-button @click="closeRegistPage" class="btn">关 闭</el-button>
				<el-button type="primary" @click="registerLogin('registerData')" class="btn">注册</el-button>
			</div>
		</el-dialog>
		
		<el-dialog
			:show-close="false"
			:close-on-click-modal="false"
			:visible.sync="needCheckVisible"
			width="400px"
			>
			<div class="tl-title">
				<img src="../../../../../assets/image/logo12306.png" alt="" style="width: 30px; height: 30px;">
				<span class="tl-tit-text">12306账号核验</span>
			</div>
			<div class="tl-content-check">
				<p class="colorRed font15">您的账号需要核验</p>
				<p class="marTop10 line-h24" v-if="cusTomerSendSms">请使用12306绑定手机号，发送666至12306，然后将12306返回的验证码填入到下方后登录</p>
				<p class="marTop10 line-h24" v-if="!cusTomerSendSms">请输入12306给您发送的验证码</p>
				<div class='marTop10 relative'>
					<span style="width: 20%;display:inline-block;">验证码</span>
					<el-input placeholder="请输入验证码" :maxlength="6" v-model="validateCode"  style="width: 80%;"></el-input> 
					<span v-if="checkSuccess" class="colorRed absolute powz">核验失败，请重新核验</span>
				</div>
			</div>
			<div slot="footer" class="marTop20 text-right">
				<el-button @click="cancelCheck" class="btn">关 闭</el-button>
				<el-button @click="alertBack" class="btn">返 回</el-button>
				<el-button type="primary" class="btn" @click="checkUser">
					<div  v-if="!loading">核 验</div>
					<div  v-if="loading">
						<span class="el-icon-loading"></span>
						核验中...
					</div>
				</el-button>
			</div>
		</el-dialog>
	
	</div>
</template>

<script>
	export default{
		name: "TrainLogin",
		props:{
			// 是否需要手机核验
			needCheck:{
				type:Boolean,
				default:false,
			},
			//火车票登录
			loginAgain:{
				type:Boolean,
				default:false,
			},
			// 传入登录人信息
			loginPerson:{
				type:Object,
				default(){
					return {};
				}
			},
			// 订单管理中使用火车票登录状态 不显示登录样式
			showTrLogin:{
				type:Boolean,
				default:true,
			},
			// 订单列表 通过属性传入 显示登录
			orderVisible:{
				type:Boolean,
				default:false,
			},
			// 订单列表 通过属性传入 显示核验
			checkVisible: {
				type:Boolean,
				default:false,
			},
			// true-正常核验流程, false-主动发送流程 默认true
			cusTomerSendSmsProp: {
				type:Boolean,
				default: true,
			},
			// true-查询订单关联的12306，false-查询预订人绑定的12306
			isQueryOrderAccount: {
				type:Boolean,
				default:false,
			},
			orderInfo: { // 订单信息 需要 orderId,orderType
				type:Object,
				default(){
					return {};
				}
			}
		},
		data() {

			// 12306 注册验证		
			// 验证手机号
			let valiMobileNo = (rule, value, callback) => {
			        var myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
			        if(!myreg.test(this.registerData.mobileNo)){
						callback(new Error('手机号码无效'));
			        }else {
						callback();
			        }	
			    };
			// 验证证件号	
			let valiIdNo = (rule, value, callback) => {
					let reg = /^[1-9][0-9]{5}(19[0-9]{2}|200[0-9]|2010)(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01])[0-9]{3}[0-9xX]$/
					if(this.registerData.idNo.length!=18 && (this.registerData.idTypeCode==1 || this.registerData.idTypeCode==2)){
						callback(new Error('证件号长度错误'));
					}else if(!reg.test(this.registerData.idNo)){
						callback(new Error('证件号码错误'));
					}else {
						callback();
			        }
			    };
			// 验证邮箱
			let vailEmail = (rule, value, callback) => {
					let res = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/;
					if(!res.test(this.registerData.email)){
						callback(new Error('邮箱格式不正确'));
					}else {
						callback();
			        }
			    };
			let validEndingNumber = (rule, value, callback) => {
				if(this.loginData.endingNumber && this.loginData.endingNumber.length != 4){
					callback(new Error('12306账号关联证件号后四位不正确'));
				}else {
					callback();
				    }
				}
			//验证两次输入的密码		
			let valiPassword = (rule, value, callback) => {
					if(this.passwordOk && value && (this.passwordOk != value)){
						callback(new Error('两次输入密码不一致'));
					} else {
						callback();
			        }
			    };
			
			
			return {
				loading: false,
				loginVisible: false, // 12306账号登录界面
				registerVisible: false, // 12306注册界面
				needCheckVisible: this.checkVisible, // 手机核验
				validateCode: '', //验证码 
				validateType: 0, //验证类型 1：注册；2：登录
				checkSuccess: false, //阻止重复验证
				loginData: { //账号密码 
					password: "",
					username: "",
					endingNumber: "",
					orderId: this.orderInfo.orderId,
					orderType: this.orderInfo.orderType,
				},
				// endingNumberShow: 0,
				isSearch16012: false, // 已经查询是否需要16012证件后四位了，默认未查询
				countryList:[], // 国家信息列表 
				accountInfo:"", // 账号信息 
				registerData:{ // 注册账号信息     
					birthday: "",
					country: "CN", 
					email: "",
					gender: "",
					idNo: "",
					idTypeCode: "1", 
					mobileNo: "", 
					name: "", 
					passengerType: 1, 
					password: "", 
					username: "", 
					validDateEnd: "",
				}, 
				manList:[  
					{
						id:'M', 
						text: '男'
					},
					{
						id:'F', 
						text: '女' 
					},
				], 
				// 证件类型 
				cardList: [  
					{
						id: '1', 
						text: '中国居民身份证'
					},
					{
						id: '2', 
						text: '港澳台居民居住证' 
					},
					{  
						id: 'C',
						text: '港澳居民来往内地通行证' 
					},
					{ 
						id: 'G',
						text: '台湾居民来往大陆通行证'
					},
					{ 
						id: 'B',
						text: '护照'
					},
					{ 
						id: 'H',
						text: '外国人永久居留身份证',
					}
				],
				// 旅客类型 
				passengerTypeList: [{ 
					id: 1,
					text: '成人票'
				},{
					id: 2,
					text: '儿童票'
				},{
					id: 3,
					text: '学生票'
				},{
					id: 4,
					text: '残军票'
				}],					
				passwordOk:'', // 确认密码
				
				// 	验证登录信息
				loginDataRules:{
					username:[{ required: true, message: '请输入账号',trigger: 'blur'}],
					password:[{ required: true, message: '请输入密码',trigger: 'blur'}],
					// { required: true, message: '请输入12306账号关联证件号后四位',trigger: 'blur'},
					endingNumber:[{
						validator: validEndingNumber,trigger: 'blur'
					}]
				},
				// 注册账号 表单验证
				registerDataRules:{
					birthday: [{ required: true, message: '请选择出生日期',trigger: 'blur'}],
					country: [{ required: true, message: '请选择国家',trigger: 'blur'}],
					email: [{ required: true, message: '请输入邮箱',trigger: 'blur'},
							{ validator:vailEmail,trigger: "blur"}],
					gender: [{ required: true, message: '请选择性别',trigger: 'blur'}],
					idNo: [ { required: true, message: '请输入证件号',trigger: 'blur'},
							{ validator:valiIdNo,trigger: "blur"}],
					idTypeCode: [{ required: true, message: '请选择证件类型',trigger: 'blur'}],
					mobileNo: [ { required: true, message: '请输入手机号',trigger: 'blur'},
								{ max: 11, min: 11,message:'手机号长度错误',trigger: 'blur'},
								{ validator:valiMobileNo,trigger: "blur"}
								],
					name: [{ required: true, message: '请输入姓名',trigger: 'blur'}],
					passengerType: [{ required: true, message: '请选择旅客类型',trigger: 'blur'}],
					password: [ { required: true, message: '请输入密码',trigger: 'blur'},
								{ min: 6,message:'密码长度不得低于6',trigger: 'blur'},
								{ validator:valiPassword,trigger: 'blur'},
								],
					username: [ { required: true, message: '请输入用户名',trigger: 'blur'},
								{ max: 11, min: 11,message:'用户名必须6-30位字母、数字或“_”,字母开',trigger: 'blur'},
								],
					validDateEnd: [{ required: true, message: '请选择证件截止时间',trigger: 'blur'}],
				},
				cusTomerSendSms: this.cusTomerSendSmsProp, // 客户发送短信666到12306
			}
		
		},
		
		mounted() {
			if(this.countryList.length==0){
				this.qeuryCountry();
			}
			
			
			if (this.isQueryOrderAccount) {
				this.queryOrderAccount();
			} else {
				this.queryBind();
			}
			
			// // 获取火车票登录页面是否出现证件号后4位输入框：1是0否
			// this.getTrEndingNumberInfo();
		},
		watch:{
			'loginData.endingNumber' (newVal) {
				if (!!newVal) {
					this.loginData.endingNumber = newVal.slice(0,4);
				}
			},
			// 传入的12306信息变化，重新查询订单关联的12306账号信息
			'orderInfo.orderId'(val) {
				if (val) {
					this.queryOrderAccount()
				}
			},
			// 火车票核验
			needCheck(bool){
				if(bool){
					this.queryCheck()
				}
			},
			// 火车票登录
			loginAgain(bool){
				if(bool){
					this.accountLogin()
				}
			},
			// 个人中心 订单管理 显示登录
			orderVisible(bool){
				if(bool){
					this.getLoginPage()
				}else{
					this.loginVisible = false;
				}
			},
			// 个人中心 订单管理 显示核验
			checkVisible(bool){
				if (bool) {
					this.needCheckVisible = true;
				}
			},
		},
		methods: {
			// 从核验页面返回登录页面
			alertBack() {
				this.needCheckVisible = false;
				if (this.validateType == 1) {
					this.registerVisible = true;
				} else {
					this.loginVisible = true;
				}
			},
			// 获取火车票登录页面是否出现证件号后4位输入框：1是0否
			getTrEndingNumberInfo() {
			  this.$conn.getConn('commu.getBParam')({
			    paramNo: 16012
			  }, (res) => {
			    let data = res.data || {};
			    this.isSearch16012 = true;
					this.endingNumberShow = data.value1;
					
					// 处理证件号后四位校验
					if(this.endingNumberShow != 1) {
						this.$delete(this.loginDataRules,'endingNumber');
					}
					
					// 判断是否可以登录
					this.checkAccountLogin();
			  })
			},
			// 显示登录页面
			getLoginPage() {
				this.loginVisible = true;	
			},
			// 取消登录
			cancelLogin(){
				this.loginVisible = false;
				this.$emit('cancelLogin', false)
			},
			// 取消核验
			cancelCheck(){
				this.needCheckVisible = false;
				this.$emit('cancelLogin', false)
			},
			// 12306账号登录
			accountLogin(formName) {
				this.$refs[formName].validate((valid) => {
				    if (valid) {
						this.loading = true;
						
						// // 是否需要身份证后四位1-需要 0-不需要
						// if (this.endingNumberShow != 1) {
						// 	this.$delete(this.loginData,'endingNumber');
						// }
						
				        this.$conn.getConn('trBook.loginAccount2',{jsfile:false})(this.loginData,(res)=>{
							let  data = res.data || {};
							this.loading = false;
							if(data.success == true){
								this.$emit('loginSuccess',false)
								this.loginVisible = false;
								this.accountInfo = this.loginData?.username??'';
								this.$emit('account12306Login', this.loginData)
								// this.queryBind()
								if(this.showTrLogin == false){
									this.loginVisible = false;
									this.validateType = 2;
									this.needCheckVisible = true;
									// this.queryCheck()
								}
								
							} else if(data.code == 7524 || data.code == 7530){
								this.cusTomerSendSms = data.code == 7530? false: true;
								//查询核验
								this.loginVisible = false;
								this.validateType = 2;
								this.needCheckVisible = true;
								// this.queryCheck()
							} else {
								this.$message.error(data.msg)
							} 
						},(err)=>{
							this.loading = false;
							this.$message.error(err.tips)
						})
				    }
				});      
			},
			//查询核验
			queryCheck(){
				this.needCheckVisible = true;
			},
			// 输入验证码核验
			checkUser(){
				this.checkSuccess = false;
				if(!this.validateCode){
					this.$message.error('验证码为空,请输入验证码')
					return 
				}
				let obj = {};
					obj = {
						  "password": this.loginData.password || this.loginPerson.password,
						  "username": this.loginData.username || this.loginPerson.username,
						  "validateCode": this.validateCode,
						  "validateType": this.validateType,
							"orderId": this.loginData.orderId,
							"orderType": this.loginData.orderType,
						}
						// 需要12306关联证件后四位
						// if(this.endingNumberShow == 1) {
						 obj.endingNumber = this.loginData.endingNumber
						// }
						
						
				this.loading = true;		
				this.$conn.getConn('trBook.validateMessage',{jsfile:false})(obj,(res)=>{
					this.loading = false;	
					let data = res.data || {};
					this.$emit('needSuccess',false)
					if(data.success==true){
						this.$message.success('核验成功')
						this.needCheckVisible = false;
						this.checkSuccess = false;
						this.accountInfo = this.loginData.username;
					}else{
						this.checkSuccess = true;
						this.$message.error(data.tips)
					}
				},(err)=>{
					this.loading = false;
					this.$message.error(err.tips, () => {
						if (err.errCode == 7527) {
							this.alertBack();
						}
					})
				})
			},
			// 查询绑定人
			queryBind(){
				this.$conn.getConn('trBook.bindList',{jsfile:false})({}).then((res)=>{
					let  data = res.data || [],
							 dataOne = data[0] || {};
					
					this.accountInfo = dataOne.username || "";
					this.loginData.username = this.accountInfo;
					this.loginData.isFast = false;
					this.loginData.password = dataOne.password;
					this.loginData.endingNumber = dataOne.endingNumber;
					// this.$emit("accountLogin",data)
					this.checkAccountLogin()
				})
			},
			// 查询订单管理的12306信息
			queryOrderAccount() {
				if(!this.orderInfo.orderId || !this.orderInfo.orderType) {
					return false;
				}
				let params = {
					orderId: this.orderInfo.orderId,
					orderType: this.orderInfo.orderType,
				}
				
				
				this.$conn.getConn('trBook.orderAccount',{jsfile:false})(params).then((res)=>{
					let  data = res.data || [];
										
					this.accountInfo = data.trainAccount || "";
					this.loginData.username = this.accountInfo;
					this.loginData.isFast = false;
					// 7527-代表账号密码不匹配，去掉密码
					this.loginData.password = this.orderInfo.errCode != '7527'? data.password: '';
					this.loginData.endingNumber = data.endingNumber;
				})
			},
			// 判断是否登录
			checkAccountLogin() {
				 // && this.isSearch16012
				if (this.loginData.username && this.loginData.password) {
					this.loginVisible = true;

					var timer = setTimeout(() => {
						this.accountLogin('loginData')
					},100)
					this.$once('hook:beforeDestroy', () => {
						timer && clearTimeout(timer);
						timer = null;
					});
				}
			},
			// 打开注册界面
			getRegisterPage(){
				this.loginVisible = false;
				this.registerVisible = true;
			},
			// 关闭注册界面
			closeRegistPage(){
				this.registerVisible = false;
				this.$emit('cancelLogin',false)
			},
			// 12306注册
			registerLogin(formName) {
				this.$refs[formName].validate((valid) => {
				    if (valid) {
				        this.$conn.getConn('trBook.registerAccount',{jsfile:false})(this.registerData,(res)=>{
							this.registerVisible = false;
							this.$message.success('注册成功')
						},(err)=>{
							this.registerVisible = false;
							this.$message.error(err.tips)
						})
				    }
				});      
				
			},
			
			// 获取国信息
			qeuryCountry(){
				this.$conn.getConn('commu.getBClass',{jsfile:false})({parNo: 101},(res)=>{
					this.countryList = res.data.list || [];
				})
			},			
			
		}
		
	}
</script>

<style scoped lang="scss">
	.tl-main{
		font-family: '微软雅黑';
		.el-card.is-always-shadow{
			border: 1px solid #E5E5E5;
			box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.02);
			border-radius: 0;
		}
		.el-card {
			/deep/ .el-card__body{
				padding: 15px;
			}
		}
		.form-content{
			text-align: left;
			border-bottom: 1px solid #E5E5E5;
			padding: 25px 30px 12px 30px;
			.el-select {
				width: 280px;
					/deep/ .el-input__inner{
						height: 35px;
						background: #FFFFFF;
						border: 1px solid #CCCCCC;
						border-radius: 0;
						
					}
			}
			/deep/ .el-form-item__label{
				line-height: 35px;
				font-size: 12px;
				font-weight: 500;
				color: #5E6669;
				text-align: left;
				padding-right: 0;
			}
			.el-input{
				width: 280px;
				line-height: 35px;
				/deep/ .el-input__inner{
					height: 35px;
					background: #FFFFFF;
					border: 1px solid #CCCCCC!important;
					border-radius: 0;
					
				}
			}
			.marBtm15{
				margin-bottom: 15px;
			}
		}
		.tl-box{
			display: flex;
			// justify-content: space-between;
			width: 100%;
			.item-l,.item-r,.item-c{
				display: inline-block;
			}
			.item-l{
				width:30px;
				height: 30px;
				text-align: left;
				img{
					width: 30px;
					height: 30px;
				}
			}
			.item-r{
				width:60px;
				text-align: right;
				font-size: 14px;
				font-weight: 500;
				color: #006BB9;
				line-height: 30px;
			}
			.item-r:hover{
				cursor: pointer;
			}
			.item-c{
				margin-left: 14px;
				width: 625px;
				text-align: left;
				font-size: 14px;
				font-weight: 500;
			}
			.p-t-color{
				color:#006BB9;
				line-height: 15px;
			}
			.p-b-color{
				font-size: 12px;
				line-height: 12px;
				padding-top: 4px;
				font-weight: 400;
				color: #999999;
			}
		}
		.errColor{
			color: red;
		}
		/deep/ .el-dialog__header{
			padding: 0;
		}
		/deep/ .el-dialog__body{
			padding: 0;
		}
		/deep/ .el-dialog__footer{
			padding: 0 30px 20px 30px;
			text-align:left;
		}
		.tl-title{
			display:flex;
			text-align: left;
			padding: 20px 30px;
			border-bottom: 1px solid #E5E5E5;
			
			.tl-tit-text{
				display: inline-block;
				font-size: 14px;
				font-weight: 800;
				color: #006BB9;
				line-height: 25px;
				padding-left: 10px;
			}
		}
		.tl-content{
			text-align: left;
			border-bottom: 1px solid #E5E5E5;
			padding: 25px 30px 12px 30px;
			/deep/ .el-form-item__label{
				line-height: 35px;
			}
			.el-input{
				width: 300px;
				line-height: 35px;
				/deep/ .el-input__inner{
					height: 35px;
					background: #FFFFFF;
					border: 1px solid #CCCCCC!important;
					border-radius: 0;
					
				}
			}
			.marBtm15{
				margin-bottom: 15px;
			}
		}
		.tl-content-check{
			text-align: left;
			border-bottom: 1px solid #E5E5E5;
			padding: 20px 30px;
			.relative{
				position: relative;
			}
			.absolute{
				position: absolute;
				top:6px;
				right: 5px;
			}
		}
		.tl-footer60{
			display:inline-block;
			width:60%;	
			.btn{
				width: 95px;
				height: 35px;
				border-radius: 0;
				font-size: 12px;
				font-weight: 800;
				line-height: 15px;
				letter-spacing: 1px;
			}	
				
		}
		.tl-footer40{
			cursor: pointer;
			display: inline-block;
			width: 40%;
			font-size: 12px;
			font-weight: 500;
			color: #006BB9;
			line-height: 15px;
		}
		.text-left{
			text-align: left;
		}
		.text-right{
			text-align: right;
		}
		.line-h24{
			line-height: 24px;
		}
		.marTop10{
			margin-top: 10px;
		}
		.marTop20{
			margin-top: 20px;
		}
		.marRight20{
			margin-right: 20px;
		}
		.tl-con-tit{
			width: 20%;
			display: inline-block;
		}
		.colorRed{
			color: red;
		}
		.font15{
			font-size: 15px;
		}
		.btn{
			width: 95px;
			height: 35px;
			font-size: 15px;
			box-sizing: border-box;
		}
	}

</style>
