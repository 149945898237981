var caozuoMixin = {};


// 数据存取
caozuoMixin.commonConfig = {
  data() {
    return {}
  },
  methods: {

    // 撤销审批
    goToCancelApprove(queryInfoObj, successFn, errFn) {

      // var btnArray = ['取消', '确认'];
      let chiHuiTips = `您是否要撤回？`;
      this.$confirm(chiHuiTips, '温馨提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        var queryString = {
          orderId: queryInfoObj.orderId,
          orderType: queryInfoObj.orderType
        };
        this.$conn.getConn("commu.vipBpmSendAppCancel")(queryString, (resp) => {
          this.$message({
            type: 'success',
            message: '撤销成功!'
          });
          successFn && successFn(resp)
        }, (err) => {
          this.$message({
            type: 'error',
            message: err.tips
        }); 	
          errFn && errFn(err)
        })
      }).catch(() => {
  
      });




    }
  }



}




export default caozuoMixin
